.countdown {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  & > .countdown_text {
    font-size: 13px;
    font-weight: 400;
    color: #fbf59b;
  }
  & > .countdown_progress {
    margin-top: 4px;
    padding: 1px;
    width: 98px;
    height: 10px;
    background-color: #497d28;
    border-radius: 5px;
    & > .progress_value {
      position: relative;
      width: 0%;
      height: 100%;
      background-color: #f8e773;
      border-radius: 10px;
      & > .progress_icon {
        position: absolute;
        top: -50%;
        right: 0;
        transform: translate(50%);
        width: 12px;
        height: 14px;
      }
    }
  }
}

@primary-color: #1DA57A;