@import "~antd/dist/antd.less";
.App {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 100vw;
  height: 100vh;
  // width: 100%;
  // height: 100%;
  // min-width: 375px;
  // min-height: 525px;
  border-radius: 8px 8px 0 0;
  background-color: transparent;
  overflow: hidden;
}

@primary-color: #1DA57A;