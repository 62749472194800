.daojishi_model {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.daojishi_model > .daojishi_box {
  position: relative;
  width: 164px;
  height: 176px;
  transform: scale3d(1.2, 1.2, 1);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.daojishi_model > .daojishi_box > .daojishi_content {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  font-family: PangMenZhengDaoBiaoTiTi;
  font-size: 40px;
  font-weight: bold;
  color: #ba6d47;
  line-height: 22px;
  text-shadow: 0px 2px 1px #813514;
}
