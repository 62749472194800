.food {
  position: absolute;
  display: flex;
  flex-direction: column;
}
.food > img {
  width: 60px;
}
.food > .food_info {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: 3px 6px;
  width: max-content;
  background: linear-gradient(90deg, #d18f36, #ca772d);
  border-radius: 8px;
}
.food > .food_info > span {
  min-width: 30px;
  font-size: 12px;
  font-weight: 400;
  color: #fbf59b;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  transform: scale(0.83333, 0.83333);
}
.ap_modal_food {
  max-width: calc(100vw - 96px) !important;
}
.ap_modal_food .ant-modal-content {
  background-color: transparent !important;
}
.ap_modal_food .ant-modal-content .ant-modal-body {
  padding: 0 !important;
}
.ap_modal1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  border: 2px solid #60b646;
  border-radius: 8px;
  overflow: hidden;
}
.ap_modal1 > .ap_modal_header1 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 0 12px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ap_modal1 > .ap_modal_header1 > .title {
  font-size: 24px;
  font-weight: 500;
  color: #fff8df;
  line-height: 32px;
  user-select: none;
  cursor: default;
}
.ap_modal1 > .ap_modal_header1 > .icon_close {
  position: absolute;
  top: 20px;
  right: 18px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  user-select: none;
  cursor: pointer;
}
.ap_modal1 > .ap_modal_body1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  background-color: rgba(245, 246, 226, 0.8);
  border-bottom: 1px solid rgba(37, 118, 13, 0.2);
}
.ap_modal1 > .ap_modal_body1 > .food_icon {
  min-width: 70px;
  max-width: 70px;
  width: 70px;
  user-select: none;
}
.ap_modal1 > .ap_modal_body1 > .food_name {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #25760d;
  line-height: 21px;
  user-select: none;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 {
  display: flex;
  flex-direction: row;
  padding: 1px;
  background-color: #25760d;
  border-radius: 34px;
  user-select: none;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .reduce,
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .add {
  min-width: 22px;
  width: 22px;
  height: 22px;
  transition: all 100ms;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .reduce:active,
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .add:active {
  transform: scale(0.9);
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value > .ap_input {
  margin: 0;
  width: 82px;
  height: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
  text-align: center;
  border: none;
  background-color: transparent;
  outline: none;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value > .ap_input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #666;
  line-height: 21px;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value > .ap_input::-webkit-outer-spin-button,
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value > .ap_input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper1 > .food_stepper_value > .ap_input[type="number"] {
  -moz-appearance: textfield;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper_box1 {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper_box1 > .food_stepper_add {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  padding: 1px 10px;
  width: max-content;
  background-color: #5ab140;
  border: 0.5px solid #25760d;
  border-radius: 34px;
  user-select: none;
  transition: all 50ms;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper_box1 > .food_stepper_add:active {
  transform: scale(0.96);
}
.ap_modal1 > .ap_modal_body1 > .food_stepper_box1 > .food_stepper_add > .label {
  color: #fff;
  line-height: 22px;
}
.ap_modal1 > .ap_modal_body1 > .food_stepper_box1 > .food_stepper_add > .value {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  line-height: 22px;
}
.ap_modal1 > .ap_modal_body1 > .food_number1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}
.ap_modal1 > .ap_modal_body1 > .food_number1 > .label {
  font-size: 12px;
  font-weight: 400;
  color: #25760d;
  line-height: 17px;
  user-select: none;
}
.ap_modal1 > .ap_modal_body1 > .food_number1 > .value {
  font-size: 12px;
  font-weight: 600;
  color: #25760d;
  line-height: 17px;
  user-select: none;
}
.ap_modal1 > .ap_modal_body1 > .integral_list {
  display: flex;
  flex-direction: row;
  padding: 0 11px !important;
  width: 100%;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box {
  padding: 0 5px;
  width: 33.3333%;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  height: 113px;
  background-color: rgba(37, 118, 13, 0.3);
  border: 1px solid transparent;
  border-radius: 8px;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item.active {
  border-color: #25760d;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item > .icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item > .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item > .info > .name {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 20px;
}
.ap_modal1 > .ap_modal_body1 > .integral_list > .integral_box > .integral_item > .info > .content {
  font-size: 12px;
  font-weight: 400;
  color: #25760d;
  line-height: 17px;
}
.ap_modal1 > .ap_modal_body1 > .integral_value {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}
.ap_modal1 > .ap_modal_body1 > .integral_value > .label {
  font-size: 15px;
  font-weight: 400;
  color: #25760d;
  line-height: 21px;
}
.ap_modal1 > .ap_modal_body1 > .integral_value > .icon {
  margin: 0 4px;
  min-width: 20px;
  width: 20px;
  height: 20px;
}
.ap_modal1 > .ap_modal_body1 > .integral_value > .value {
  font-size: 15px;
  font-weight: 600;
  color: #25760d;
  line-height: 21px;
}
.ap_modal1 > .ap_modal_body1 > .reward {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ap_modal1 > .ap_modal_body1 > .reward > .reward_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  min-width: 132px;
  width: 132px;
  height: 132px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.ap_modal1 > .ap_modal_body1 > .reward > .reward_box > img {
  min-width: 84px;
  width: 84px;
  height: 84px;
}
.ap_modal1 > .ap_modal_body1 > .reward > .reward_title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #25760d;
  line-height: 20px;
}
.ap_modal1 > .ap_modal_footer1 {
  display: flex;
  flex-direction: row;
  background-color: rgba(245, 246, 226, 0.8);
}
.ap_modal1 > .ap_modal_footer1 > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 5px 12px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #25760d;
  line-height: 17px;
  user-select: none;
}
.ap_modal1 > .ap_modal_footer1 > .left > .animal {
  margin-left: 4px;
  min-width: 40px;
  width: 40px;
  height: 40px;
}
.ap_modal1 > .ap_modal_footer1 > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 5px;
}
.ap_modal1 > .ap_modal_footer1 > .right > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f3a127;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  transition: all 100ms;
  user-select: none;
}
.ap_modal1 > .ap_modal_footer1 > .right > .btn:active {
  background-color: rgba(243, 161, 39, 0.8);
}
.ap_modal1 > .ap_modal_footer1 > .btn_box {
  padding: 16px;
  width: 100%;
}
.ap_modal1 > .ap_modal_footer1 > .btn_box > .btn_payment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  width: 100%;
  background-color: #f3a127;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  transition: all 100ms;
  user-select: none;
}
.ap_modal1 > .ap_modal_footer1 > .btn_box > .btn_payment:active {
  background-color: rgba(243, 161, 39, 0.8);
}
.ap_modal1 > .ap_modal_footer1 > .reward_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px 16px;
}
.ap_modal1 > .ap_modal_footer1 > .reward_box > .btn_yes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  width: 100%;
  background-color: #f3a127;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  transition: all 100ms;
  user-select: none;
}
.ap_modal1 > .ap_modal_footer1 > .reward_box > .btn_yes:active {
  background-color: rgba(243, 161, 39, 0.8);
}
.ap_modal1 > .ap_modal_footer1 > .reward_box > .label {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #25760d;
  line-height: 17px;
}
