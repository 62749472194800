.game-tip-content {
    font-size: 14px;
    color: #FFF6F1;
}

.game-tip-progress {
    background-color: #FCBC51;
    background-image: linear-gradient(45deg, #fca311 25%, transparent 25%, transparent 50%, #fca311 50%, #fca311 75%, transparent 75%, transparent);
    height: 12px;
    border-radius: 34px;
}

.game-tip-progress-container {
    height: 15px;
    background-color: #E97001;
    width: 93px;
    border: 1.5px solid #E97001;
    border-radius: 34px;
    box-shadow: 0px 0px 4px #AE5700;
}