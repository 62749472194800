.ap_modal_consume {
  padding: 0 !important;
  max-width: calc(100vw - 36px) !important;
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
    }
  }
}
.ap_modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  & > .ap_modal_box2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    border: 2px solid #60b646;
    border-radius: 8px;
    overflow: hidden;
    & > .ap_modal_header2 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px 0 12px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      & > .title {
        font-size: 24px;
        font-weight: 500;
        color: #fff8df;
        line-height: 32px;
        user-select: none;
        cursor: default;
      }
      & > .icon_close {
        position: absolute;
        top: 20px;
        right: 18px;
        min-width: 24px;
        width: 24px;
        height: 24px;
        user-select: none;
        cursor: pointer;
      }
    }
    & > .ap_modal_body2 {
      display: flex;
      flex-direction: column;
      height: 320px;
      background-color: rgba(245, 246, 226, 0.8);
      overflow: hidden;
      & > .consume {
        display: flex;
        flex-direction: column;
        padding: 20px 10px 16px;
        height: 100%;
        & > .consume_th {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px;
          & > .item {
            font-size: 13px;
            font-weight: 500;
            color: rgba(37, 118, 13, 1);
            line-height: 18px;
          }
        }
        & > .consume_list {
          display: flex;
          flex-direction: column;
          padding: 0 !important;
          overflow-x: hidden;
          overflow-y: auto;
          & > .consume_box {
            display: flex;
            flex-direction: row;
            margin-top: 8px;
            padding-left: 1px;
            padding-bottom: 1px;
            width: 100%;
            height: 118px;
            background-color: rgba(213, 150, 57, 1);
            border-radius: 4px;
            & > .consume_item {
              display: flex;
              flex-direction: column;
              padding: 6px 14px 6px 16px;
              width: 100%;
              height: 100%;
              background-color: rgba(242, 221, 166, 1);
              border-radius: 4px;
              overflow: hidden;
              & > .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 6px;
                border-bottom: 1px solid rgba(151, 92, 3, 0.2);
                & > .value {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  & > img {
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                  }
                  & > span {
                    margin-left: 2px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(37, 118, 13, 1);
                    line-height: 17px;
                  }
                }
                & > .status {
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(225, 40, 69, 1);
                  line-height: 17px;
                  &.success {
                    color: rgba(37, 118, 13, 1);
                  }
                }
              }
              & > .content {
                display: flex;
                flex-direction: column;
                padding-top: 6px;
                & > .row {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  &.margin_top4 {
                    margin-top: 4px;
                  }
                  & > .label {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(37, 118, 13, 1);
                    line-height: 17px;
                  }
                  & > img {
                    min-width: 26px;
                    width: 26px;
                    height: 26px;
                  }
                }
                & > .row2 {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  &.margin_top4 {
                    margin-top: 4px;
                  }
                  & > .label {
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(37, 118, 13, 1);
                    line-height: 17px;
                  }
                  & > img {
                    min-width: 26px;
                    width: 26px;
                    height: 26px;
                  }
                  & > .img_list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    & > img {
                      // margin-left: -13px;
                      min-width: 26px;
                      width: 26px;
                      height: 26px;
                    }
                  }
                }
              }
              & > .bottom {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                align-self: end;
                & > .label {
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(37, 118, 13, 1);
                  line-height: 17px;
                  transform: scale(0.83333, 0.83333);
                }
              }
            }
          }
        }
      }
    }
  }
  & > .label {
    margin-top: 6px;
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: rgba(223, 221, 193, 1);
    line-height: 14px;
    transform: scale(0.83333, 0.83333);
  }
}
.info_img {
  display: flex;

  min-width: 26px;
  width: 26px;
  height: 26px;
}
@primary-color: #1DA57A;