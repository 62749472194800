.ap_modal_party_rules {
  padding: 0 !important;
  max-width: calc(100vw - 36px) !important;
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
    }
  }
}
.ap_modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  & > .ap_modal_box2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    border: 2px solid #60b646;
    border-radius: 8px;
    overflow: hidden;
    & > .ap_modal_header2 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px 0 12px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      & > .title {
        font-size: 24px;
        font-weight: 500;
        color: #fff8df;
        line-height: 32px;
        user-select: none;
        cursor: default;
      }
      & > .icon_close {
        position: absolute;
        top: 20px;
        right: 18px;
        min-width: 24px;
        width: 24px;
        height: 24px;
        user-select: none;
        cursor: pointer;
      }
      & > .course {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 12px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 0px 0px 0px 8px;
        border-left: 1px solid rgba(96, 182, 70, 0.7);
        border-bottom: 1px solid rgba(96, 182, 70, 0.7);
        &:active {
          opacity: 0.9;
        }
        & > span {
          font-size: 14px;
          font-weight: 400;
          color: #25760d;
          line-height: 16px;
        }
      }
    }
    & > .ap_modal_body2 {
      display: flex;
      flex-direction: column;
      height: 320px;
      background-color: rgba(245, 246, 226, 0.8);
      overflow: hidden;
      & > .play_rules {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        background-color: rgba(37, 118, 13, 1);
        overflow-x: hidden;
        overflow-y: auto;
        & > a {
          padding: 4px 8px;
          font-size: 14px;
          font-weight: 400;
          color: blue;
          line-height: 20px;
        }
        & > img {
          width: 100%;
        }
      }
    }
  }
  & > .label {
    margin-top: 6px;
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: rgba(223, 221, 193, 1);
    line-height: 14px;
    transform: scale(0.83333, 0.83333);
  }
}

@primary-color: #1DA57A;