.ap_modal_course {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100vw;
  width: 100vw !important;
  height: 100% !important;
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 100% !important;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      width: 100% !important;
      height: 100% !important;
      & > .ap_modal_course_box {
        position: relative;
        width: 100% !important;
        height: 100% !important;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        & > .ap_modal_course_box_btn {
          position: absolute;
          top: 10px;
          right: 16px;
          padding: 4px 16px;
          background-color: rgba(51, 51, 51, 0.9);
          border-radius: 24px;
          font-size: 12px;
          font-weight: 400;
          color: #fff;
          line-height: 20px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;