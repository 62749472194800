input {
  caret-color: red;
}

.input-number-Model-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 0px 0px 0px;
  width: 322;
  background-color: #ffffff;
}

.input-number-Model-title {
  align-self: center;
  font-size: 18px;
  color: #050303;
  font-weight: medium;
}

.goods-model-one {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.goods-model-one-img {
  align-self: center;
}

.goods-model-one-text {
  align-self: center;
  padding-top: 8px;
}

.goods-model-one-description {
  align-self: center;
  color: #979797;
  font-size: 12px;
}

.goods-model-one-button {
  background: linear-gradient(to right, #cc64e1, #cc64e1);

  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}

.ant-modal-content {
  background: transparent;
}

.input-number-Model {
  display: flex;
  flex-direction: column;
}

.goods-model-close {
  align-self: center;
  margin-top: 20px;
}

.goods-model-list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.goods-model-list-button {
  background: #7435fd;

  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}

.input-number-Model-item {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goods-model-item-text {
  padding-left: 8px;
}

.input-number-Model-item-right {
  display: flex;
  align-items: center;
}

.input-number-Model-item-left {
  font-size: 15px;
  color: #343537;
}

.input-number-Model-input {
  margin: 8px 20px 20px 20px;
  border-radius: 8px;
  height: 44px;
  border: 0.5px solid #D3D3D3;
  padding: 6px 10px;
}

.input-number-Model-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 0.5px solid #F6F6F6;
  height: 52px;
}

.input-number-Model-footer-first {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  font-size: 16px;
  color: #979797
}

.input-number-Model-footer-second {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  font-size: 16px;
  color: #F03550;
  font-weight: medium;
  border-left: 0.5px solid #F6F6F6;
}

.ant-modal-content {
  box-shadow: none;
}
@primary-color: #1DA57A;