.show_game_end_model {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.show_game_end_model > .show_game_end_model_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 16px;
  background-color: rgba(38, 119, 13, 0.56);
  border: 2px solid rgba(38, 119, 13, 0.72);
  border-radius: 8px;
}
.show_game_end_model > .show_game_end_model_box > .show_game_end_model_content {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  line-height: 24px;
}
