.star-add-container {
  position: absolute;
  top: 104px;
  left: 0px;
  z-index: 100;
}

.jinfen-item-container:last-child {
  margin-right: 0px;
}

.jinfen-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8484841a;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
  margin-right: 9px;
  border-radius: 8px;
  &.jinfen-item-active {
    outline: 1px solid #ff3f9c;
  }
  & > .jinfen-item-price-text {
    font-size: 14px;
    color: #ffffff;
    padding-top: 8px;
  }
  & > .jinfen-item-description-text {
    font-size: 12px;
    color: #979797;
  }
}

.jinfen-button-container {
  margin-top: 10px;
  margin-bottom: 30px;
  & > .jinfen-button {
    height: 44px;
    margin: 0px 16px;
    border-radius: 8px;
    background: #f3a127;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
  }
}

.jinfen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  & > .jinfen-title {
    font-size: 17;
    color: #ffffff;
    align-self: center;
    margin-top: 20px;
  }
  & > .jinfen-close {
    position: absolute;
    top: 20px;
    right: 16px;
  }
  & > .jinfen-show-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 18px 16px 0px 16px;
    & > .jinfen-show-container-count-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .jinfen-show-container-count {
        color: white;
        margin-left: 4px;
      }
    }
  }
}

.shengdou-container-count {
  color: white;
  margin-left: 4px;
  margin-right: 8px;
}

.jinfen-get-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 16px 16px 16px;
}

.star-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: hsla(83, 100%, 30%, 0.9);
  padding: 4px 5px 4px 0px;
  border-radius: 0px 23px 23px 0px;
  z-index: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
}

.star-count {
  font-size: 15px;
  color: #ffffff;
  padding-left: 2px;
  padding-right: 10px;
}

.game-tip {
  align-self: center;
}

.game {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > .game_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 16px;
    width: 100%;
    height: max-content;
    // background-color: #25760d;
      background-color: #B05B28;
    z-index: 400;
    & > .brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .title {
        margin: 0;
        font-size: 19px;
        font-weight: 600;
        // color: moccasin;
        color: #fff;
        line-height: 25px;
      }
      & > .rule {
        margin-left: 6px;
        min-width: 18px;
        width: 18px;
        height: 18px;
      }
    }
    & > .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px;
      // background-color: rgb(0, 100, 0);
        background-color: #844119;
      border-radius: 50px;
      & > .action_item {
        width: max-content;
        height: max-content;
        font-size: 14px;
        font-weight: 400;
        // color: #c3dea4;
        color: #fff;
        line-height: 20px;
        &.divider {
          margin: 0 4px;
          background-color: transparent;
        }
      }
    }
  }
  & > .game_main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url("../../assets/images/course/pic.jpg");
    // background-image: url("../../assets/images/dong.jpg");
    // background-image: url("../../assets/bg@2x.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    & > .shitou {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50px;
      z-index: 500;
    }
    & > .sum {
      position: absolute;
      top: 24px;
      right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 2px 1px 3px;
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(177, 244, 89, 0.6);
      border-radius: 12px;
      z-index: 20;
      & > .icon {
        min-width: 17px;
        width: 17px;
        height: 17px;
      }
      & > .add {
        min-width: 21px;
        width: 21px;
        height: 21px;
      }
      & > span {
        text-align: center;
        min-width: 42px;
        font-size: 13px;
        font-weight: 400;
        color: #497d28;
      }
    }
    & > .animal {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      // 动物大小
      width: 80px;
      height: 96px;
      // background-color: #fff;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // opacity: 0.5;
      transition-property: top, left;
      transition-timing-function: linear;
      z-index: 8;
      & > .animal_container {
        position: absolute;
        top: -12px;
        left: 50%;
        padding: 1px;
        width: 70%;
        height: 10px;
        transform: translateX(-50%);
        background-color: moccasin;
        box-shadow: 1px 1px 2px hsla(0, 0%, 0%, 0.25);
        border-radius: 5px;
        overflow: hidden;
        & > .animal_container_progress {
          width: 100%;
          height: 100%;
          background-color: #ff4f1c;
          border-radius: 5px;
        }
      }
      & > img {
        min-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.video_box{
  position: absolute;
  width: 100%;
  height: 100%;
}


@primary-color: #1DA57A;