.ap_modal_food {
  max-width: calc(100vw - 96px) !important;
  .ant-modal-content {
    background-color: transparent !important;
    .ant-modal-body {
      padding: 0 !important;
    }
  }
}
.ap_modal1 {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 96px);
  width: 100%;
  height: max-content;
  border: 2px solid #60b646;
  border-radius: 8px;
  overflow: hidden;
  & > .ap_modal_header1 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px 0 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    & > .title {
      font-size: 24px;
      font-weight: 500;
      color: #fff8df;
      line-height: 32px;
      user-select: none;
      cursor: default;
    }
    & > .icon_close {
      position: absolute;
      top: 20px;
      right: 18px;
      min-width: 24px;
      width: 24px;
      height: 24px;
      user-select: none;
      cursor: pointer;
    }
  }
  & > .ap_modal_body1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0 24px;
    background-color: rgba(245, 246, 226, 0.8);
    border-bottom: 1px solid rgba(37, 118, 13, 0.2);
    & > .food_icon {
      min-width: 70px;
      max-width: 70px;
      width: 70px;
      user-select: none;
    }
    & > .food_name {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 600;
      color: #25760d;
      line-height: 21px;
      user-select: none;
    }
    & > .food_stepper {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      padding: 1px;
      background-color: #25760d;
      border-radius: 34px;
      user-select: none;
      & > .reduce,
      & > .add {
        min-width: 22px;
        width: 22px;
        height: 22px;
        transition: all 100ms;
        &:active {
          transform: scale(0.9);
        }
      }
      & > .food_stepper_value {
        // margin-right: 29px;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        line-height: 21px;
        & > .ap_input {
          margin: 0;
          width: 82px;
          height: 100%;
          font-size: 15px;
          font-weight: 400;
          color: #fff;
          line-height: 21px;
          text-align: center;
          border: none;
          background-color: transparent;
          outline: none;
          &::placeholder {
            font-size: 15px;
            font-weight: 400;
            color: #666;
            line-height: 21px;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
          }
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
    & > .food_number {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
      & > .label {
        font-size: 12px;
        font-weight: 400;
        color: rgba(37, 118, 13, 1);
        line-height: 17px;
        user-select: none;
      }
      & > .value {
        font-size: 12px;
        font-weight: 600;
        color: rgba(37, 118, 13, 1);
        line-height: 17px;
        user-select: none;
      }
    }
    & > .integral_list {
      display: flex;
      flex-direction: row;
      padding: 0 11px !important;
      width: 100%;
      & > .integral_box {
        padding: 0 5px;
        width: 33.3333%;
        & > .integral_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px 0;
          height: 113px;
          background-color: rgba(37, 118, 13, 0.3);
          border: 1px solid transparent;
          border-radius: 8px;
          &.active {
            border-color: rgba(37, 118, 13, 1);
          }
          & > .icon {
            min-width: 50px;
            width: 50px;
            height: 50px;
          }
          & > .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 8px;
            & > .name {
              font-size: 14px;
              font-weight: 400;
              color: #fff;
              line-height: 20px;
            }
            & > .content {
              font-size: 12px;
              font-weight: 400;
              color: rgba(37, 118, 13, 1);
              line-height: 17px;
            }
          }
        }
      }
    }
    & > .integral_value {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 24px;
      & > .label {
        font-size: 15px;
        font-weight: 400;
        color: rgba(37, 118, 13, 1);
        line-height: 21px;
      }
      & > .icon {
        margin: 0 4px;
        min-width: 20px;
        width: 20px;
        height: 20px;
      }
      & > .value {
        font-size: 15px;
        font-weight: 600;
        color: rgba(37, 118, 13, 1);
        line-height: 21px;
      }
      & > .jifen_switch {
        margin: 0 4px;
        &.ant-switch-checked {
          background-color: #60b646;
        }
      }
      & > .jifen_label {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }
    }
    & > .shendou {
      position: absolute;
      top: 6px;
      right: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 5px 3px 5px;
      background-color: #60b646;
      border-radius: 23px 0 0 23px;
      & > .icon {
        margin: 0 2px;
        min-width: 20px;
        width: 20px;
        height: 20px;
      }
      & > .icon2 {
        margin: 0 2px;
        min-width: 18px;
        width: 18px;
        height: 18px;
      }
      & > .value {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 20px;
      }
    }
    & > .reward {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > .reward_box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        min-width: 132px;
        width: 132px;
        height: 132px;
        // background-image: url("@/assets/images/GameView/bg_gift@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        & > img {
          min-width: 84px;
          width: 84px;
          height: 84px;
        }
      }
      & > .reward_title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(37, 118, 13, 1);
        line-height: 20px;
      }
    }
  }
  & > .ap_modal_footer1 {
    display: flex;
    flex-direction: row;
    background-color: rgba(245, 246, 226, 0.8);
    & > .left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 5px 12px 8px;
      font-size: 12px;
      font-weight: 400;
      color: #25760d;
      line-height: 17px;
      user-select: none;
      & > .animal {
        margin-left: 4px;
        min-width: 40px;
        width: 40px;
        height: 40px;
      }
    }
    & > .right {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 16px 16px 5px;
      & > .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(243, 161, 39, 1);
        border-radius: 35px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 20px;
        transition: all 100ms;
        user-select: none;
        &:active {
          background-color: rgba(243, 161, 39, 0.8);
        }
      }
    }
    & > .btn_box {
      padding: 16px;
      width: 100%;
      & > .btn_payment {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0;
        width: 100%;
        background-color: rgba(243, 161, 39, 1);
        border-radius: 35px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 20px;
        transition: all 100ms;
        user-select: none;
        &:active {
          background-color: rgba(243, 161, 39, 0.8);
        }
      }
    }
    & > .reward_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 16px 16px;
      & > .btn_yes {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0;
        width: 100%;
        background-color: rgba(243, 161, 39, 1);
        border-radius: 35px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        line-height: 20px;
        transition: all 100ms;
        user-select: none;
        &:active {
          background-color: rgba(243, 161, 39, 0.8);
        }
      }
      & > .label {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #25760d;
        line-height: 17px;
      }
    }
  }
}

@primary-color: #1DA57A;