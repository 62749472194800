.game_main_model {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.game_main_model > .game_main_model_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.game_main_model > .game_main_model_box > .game_main_model_content {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.game_main_model > .game_main_model_box > .game_main_model_content > .m_tags {
  position: absolute;
  left: 50%;
  bottom: 22.8571428%;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: 400;
  color: #fbf59b;
  white-space: nowrap;
}
