.ap_modal_party {
  padding: 0 !important;
  max-width: calc(100vw - 36px) !important;
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
    }
  }
}
.ap_modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  & > .ap_modal_box2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    border: 2px solid #60b646;
    border-radius: 8px;
    overflow: hidden;
    & > .ap_modal_header2 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px 0 12px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      & > .title {
        font-size: 24px;
        font-weight: 500;
        color: #fff8df;
        line-height: 32px;
        user-select: none;
        cursor: default;
      }
      & > .icon_close {
        position: absolute;
        top: 20px;
        right: 18px;
        min-width: 24px;
        width: 24px;
        height: 24px;
        user-select: none;
        cursor: pointer;
      }
    }
    & > .ap_modal_body2 {
      display: flex;
      flex-direction: column;
      height: 320px;
      background-color: rgba(245, 246, 226, 0.8);
      overflow: hidden;
      & > .party {
        display: flex;
        flex-direction: column;
        padding: 20px 10px 16px;
        height: 100%;
        & > .party_th {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px 0 12px;
          & > .item {
            font-size: 13px;
            font-weight: 500;
            color: rgba(37, 118, 13, 1);
            line-height: 18px;
          }
        }
        & > .party_list {
          display: flex;
          flex-direction: column;
          padding: 0 !important;
          overflow-x: hidden;
          overflow-y: auto;
          & > .party_box {
            display: flex;
            flex-direction: row;
            margin-top: 8px;
            padding-left: 1px;
            width: 100%;
            background-color: rgba(213, 150, 57, 1);
            border-radius: 4px;
            & > .party_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 6px 10px 6px 4px;
              width: 100%;
              height: 100%;
              background-color: rgba(242, 221, 166, 1);
              box-shadow: 0px 1px 0px 0px rgba(197, 164, 81, 1);
              border-radius: 4px;
              overflow: hidden;
              & > .time {
                max-width: 80px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(167, 112, 31, 1);
                line-height: 12px;
                transform: scale(0.83333, 0.83333);
              }
             
             
            }
          }
        }
      }
    }
  }
  & > .label {
    margin-top: 6px;
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: rgba(223, 221, 193, 1);
    line-height: 14px;
    transform: scale(0.83333, 0.83333);
  }
}
.animals {
   flex: 1;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;

   // margin-right: 36px;
   &>img {
     min-width: 36px;
     width: 36px;
     height: 36px;
   }

   &>span {
     font-size: 12px;
     font-weight: 400;
     color: rgba(37, 118, 13, 1);
     line-height: 17px;
   }
 }
.foods {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;

   // width: 102px;
   &>img {
     min-width: 28px;
     width: 28px;
     height: 28px;
   }

   &>.foods_img {
     min-width: 70px;
     width: 70px;
    //  width: max-content;
     height: 28px;
   }

   &>span {
     min-width: 32px;
     font-size: 12px;
     font-weight: 400;
     color: rgba(37, 118, 13, 1);
     line-height: 17px;
   }
 }
@primary-color: #1DA57A;